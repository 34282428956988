import { Container, Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LogoEsc from "../../assets/logo/partners/esc.png";
import LogoOnlus from "../../assets/logo/partners/onlus.png";
import LogoSpc from "../../assets/logo/partners/spc.png";
import { useI18next } from "gatsby-plugin-react-i18next";

const OurPartners = () => {
  const { t } = useI18next();
  return (
    <Container fixed sx={{ p: 0 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: 4, md: 8 },
          my: { xs: 6, md: 12, xl: 24 },
          alignItems: "center",
        }}
      >
        <Typography variant="h2">{t("home.ourPartners")}</Typography>
        <Grid
          container
          justifyContent="space-between"
          columnSpacing={{ xs: 2, md: 10, xl: 18 }}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item>
            <img src={LogoOnlus} alt="Logo Onlus" />
          </Grid>
          <Grid item>
            <img src={LogoSpc} alt="Logo SPC" />
          </Grid>
          <Grid item>
            <img src={LogoEsc} alt="Logo ESC" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OurPartners;
