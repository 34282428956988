import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React, { useEffect } from "react";
import { getVideoUrl } from "../../utils/video";
import Vimeo from "@u-wave/react-vimeo";
import { useMeasure } from "react-use";

const videoDimensionsLarge = {
  width: "486px",
  height: "273px",
};

const BrickVideoText = ({
  videoId,
  title,
  subtitle,
  onEndLessonVideo,
  isMainHomeVideo = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [ref, { width }] = useMeasure();
  const toPixel = (number) => number.toString().split(".")[0] + "px";
  const videoDimensionsMobile = {
    width: toPixel(width),
    height: toPixel((width * 148) / 263),
  };
  const videoDimensions = isMobile
    ? videoDimensionsMobile
    : videoDimensionsLarge;

  // Refresh vimeo to allow to update the GTM datalayer
  useEffect(() => {
    if (window.__vimeoRefresh) {
      window.__vimeoRefresh();
    }
  }, []);
  return (
    <Container
      ref={ref}
      maxWidth="lg"
      sx={{ my: { xs: 1, lg: 4 }, mx: "auto" }}
      style={{ paddingLeft: "16px", paddingRigth: "16px" }}
    >
      <Grid
        container
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item lg={6} order={{ xs: 1, lg: 2 }}>
          <Box
            sx={{
              ...videoDimensions,
              borderRadius: "15px",
              overflow: "hidden",
              textAlign: "center",
              mx: { xs: "auto" },
              marginTop: 2,
            }}
          >
            <Vimeo
              responsive
              title="Event"
              video={getVideoUrl(videoId)}
              onEnd={onEndLessonVideo}
              width={(videoDimensions.width || "640px").slice(0, -2)}
              height={(videoDimensions.width || "360px").slice(0, -2)}
              frameBorder="0"
              borderRadius="15px"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                ...videoDimensions,
                height: (videoDimensions.width || "360px").slice(0, -2),
                borderRadius: "15px",
              }}
            />
          </Box>
        </Grid>
        {(title || subtitle) && (
          <Grid item lg={6} order={{ xs: 2, lg: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                my: { xs: 4, md: 2 },
              }}
            >
              <Typography
                color="grey.servier_1"
                variant={
                  isMobile && !isMainHomeVideo ? "imageMobileTitle" : "h3"
                }
              >
                {title}
              </Typography>
              <Typography
                color="grey.servier_1"
                variant={isMobile ? "imageMobileDesc" : "body1"}
              >
                {subtitle}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default BrickVideoText;
