import React from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import CustomButtonTextAndIcon from "../button/custom-button-icon";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useI18next } from "gatsby-plugin-react-i18next";
import { downloadFile } from "../../utils";

const CardFile = ({ title, description, nameFile, fileRef }) => {
  const { t } = useI18next();

  return (
    <Card
      variant="outlined"
      sx={{
        width: 285,
        height: 266,
        boxShadow: "0px 4px 35px rgba(0, 0, 0, 0.05)",
        border: 0,
        borderRadius: "15px",
        p: "30px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          component="div"
          sx={{ fontWeight: 900, fontSize: "18px" }}
        >
          {title}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {description || t("defaultDescription")}
        </Typography>
      </CardContent>

      <CardActions style={{ justifyContent: "center" }}>
        <CustomButtonTextAndIcon
          onClick={() => downloadFile(nameFile, fileRef)}
          startIcon={<FileDownloadIcon />}
          text={t("download")}
          width="255px"
        />
      </CardActions>
    </Card>
  );
};

export default CardFile;
