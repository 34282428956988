import { useTheme } from "@mui/styles";
import { graphql, navigate, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import React, { useCallback } from "react";
import { formatDate } from "../../utils";
import CustomButtonText from "../button/custom-button";
import CardLink from "../cards/card-link";
import ListOfCards from "../cards/list-of-cards";
import isEmpty from "lodash/isEmpty";
import { useMediaQuery } from "@mui/material";

const BrickSeeMoreEvents = ({ content, thumbs }) => {
  const theme = useTheme();
  const { t, language } = useI18next();

  const onClick = useCallback(() => {
    window.dataLayer?.push({
      event: "click_exit_link",
      click_text: content.buttonText,
      click_url: "/live-events",
    });
  }, [content]);

  const getTherapeuticArea = (data, live_event) => {
    return isEmpty(live_event)
      ? null
      : data.allStrapiTherapeuticalArea.edges.find(
          (th) => th.node.strapiId === live_event.therapeutical_area
        );
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const events = [];
  if (content.live_event_1) events.push(content.live_event_1);
  if (content.live_event_2) events.push(content.live_event_2);
  if (content.live_event_3) events.push(content.live_event_3);
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiEditorialContentType {
            edges {
              node {
                strapiId
                Slug
                id
                Name
                color
              }
            }
          }
          allStrapiTherapeuticalArea {
            edges {
              node {
                strapiId
                Name
                id
                color
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <ListOfCards
            title={content.title}
            cards={events
              .map((live_event, i) => {
                const therapeuticArea = getTherapeuticArea(data, live_event);
                return isEmpty(live_event) ? null : (
                  <CardLink
                    title={live_event.Title}
                    type="event"
                    image={
                      thumbs[i] ? (
                        <GatsbyImage
                          image={
                            thumbs[i].localFile.childImageSharp.gatsbyImageData
                          }
                          alt={
                            live_event.thumbnail.alternativeText
                              ? live_event.thumbnail.alternativeText
                              : ""
                          }
                        />
                      ) : null
                    }
                    date={formatDate(live_event.date)}
                    labelText={t("events")}
                    labelColor={theme.palette.servier.purple2}
                    link={`/live-events/${live_event.Slug}`}
                    therapeuticAreaLabel={therapeuticArea.node.Name}
                    therapeuticAreaColor={therapeuticArea.node.color}
                  />
                );
              })
              .filter((card) => null !== card)}
            button={
              content.buttonText ? (
                <CustomButtonText
                  text={content.buttonText}
                  style={{
                    letterSpacing: "0.1em",
                    minWidth: isMobile ? "330px" : "",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    onClick();
                    navigate(`/${language === "en" ? "en" : "es"}/live-events`);
                  }}
                />
              ) : (
                <></>
              )
            }
            backgroundColor={content.backgroundColor}
          />
        );
      }}
    />
  );
};

export default BrickSeeMoreEvents;
