import React from "react";
import HomeMedicalInformation from "../misc/home-medical-information";
import OurPartners from "../misc/ourPartners";

const BrickCustomBlock = ({ type }) => {
  switch (type) {
    case "HomeMedicalInformation":
      return <HomeMedicalInformation />;
    case "OurPartners":
      return <OurPartners />;
    default:
      return <></>;
  }
};

export default BrickCustomBlock;
