import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const BrickImageText = ({
  title,
  subtitle,
  gatsbyImageData,
  alternativeText,
  mobileImageStyle,
  mobileGatsbyImageData,
  isHeader = false,
  isMap = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (isSmallMobile && isHeader) {
    return null;
  }
  if (isMobile) {
    return (
      <BrickImageTextMobile
        title={title}
        subtitle={subtitle}
        gatsbyImageData={
          mobileGatsbyImageData && isSmallMobile
            ? mobileGatsbyImageData
            : gatsbyImageData
        }
        alternativeText={alternativeText}
        imageStyle={mobileImageStyle}
        isMap={isMap}
      />
    );
  }
  if (!(title || subtitle))
    return (
      <Container disableGutters maxWidth="false" sx={{ p: 0 }}>
        <div
          style={{
            position: "relative",
            top: "50%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <GatsbyImage
            style={{
              gridArea: "1/1",
              borderRadius: "0px",
              maxHeight: isHeader ? "30vw" : "",
            }}
            alt={alternativeText ? alternativeText : "no desc"}
            image={gatsbyImageData}
            layout="fullWidth"
          />
        </div>
      </Container>
    );
  return (
    <Container maxWidth="lg" sx={{ my: { xs: 1, lg: 4 }, mx: "auto" }}>
      <div style={{ position: "relative" }}>
        <Grid container alignItems="center" justifyContent="center" spacing={5}>
          {(title || subtitle) && (
            <Grid item lg={6} order={{ xs: 2, lg: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  my: { xs: 2 },
                }}
              >
                <Typography color="grey.servier_1" variant="h2">
                  {title}
                </Typography>
                <Typography color="grey.servier_1" variant="body1">
                  {subtitle}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item lg={6} order={{ xs: 1, lg: 2 }}>
            <Box
              sx={{
                borderRadius: "15px",
                overflow: "hidden",
                textAlign: "center",
                mx: { xs: "auto" },
              }}
            >
              <div style={{ position: "relative" }}>
                <GatsbyImage
                  style={{
                    gridArea: "1/1",
                  }}
                  alt={alternativeText ? alternativeText : ""}
                  image={gatsbyImageData}
                  layout="fullWidth"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const BrickImageTextMobile = ({
  title,
  subtitle,
  gatsbyImageData,
  alternativeText,
  imageStyle,
  isMap,
}) => {
  return (
    <Grid sx={{ mx: isMap ? 0 : 2 }}>
      <Grid>
        <div
          style={{
            position: "relative",
            top: "50%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <GatsbyImage
            alt={alternativeText ? alternativeText : "no desc"}
            image={gatsbyImageData}
            layout="fullWidth"
            style={
              imageStyle
                ? imageStyle
                : { height: "427px", borderRadius: "15px" }
            }
          />
        </div>
      </Grid>
      {(title || subtitle) && (
        <Grid item xs={12} sx={{ p: 0, m: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              p: { xs: 0, md: 4 },
            }}
          >
            {title && (
              <Typography variant="imageMobileTitle">{title}</Typography>
            )}
            <Typography variant="imageMobileDesc">{subtitle}</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default BrickImageText;
