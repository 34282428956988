import React from "react";
import BrickCustomBlock from "./custom-block";
import BrickDownloadContent from "./download-content";
import BrickImageText from "./image-text";
import BrickNewsletter from "./newsletter";
import BrickRichText from "./rich-text";
import BrickSeeMore from "./see-more";
import BrickSeeMoreCourses from "./see-more-courses";
import BrickSeeMoreEvents from "./see-more-events";
import BrickVideoText from "./video-text";
import { Container } from "@mui/material";

const Brick = ({
  content,
  imageContent,
  seeMoreThumbsContent,
  seeMoreEventsThumbsContent,
  seeMoreCoursesThumbsContent,
  downloadsContent,
  smallTitle = false,
  isMainHomeVideo = false,
}) => {
  switch (content.strapi_component) {
    case "content.image-text":
      return (
        <BrickImageText
          title={content.title}
          subtitle={content.Caption}
          gatsbyImageData={
            imageContent.localFile.childImageSharp.gatsbyImageData
          }
          alternativeText={content.Image.alternativeText}
          mobileImageStyle={{
            borderRadius: content.Caption ? "15px" : "",
          }}
        />
      );
    case "content.rich-text":
      return (
        <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <BrickRichText text={content.richText} />
        </Container>
      );
    case "content.see-more":
      return <BrickSeeMore content={content} thumbs={seeMoreThumbsContent} />;
    case "content.see-more-events":
      return (
        <BrickSeeMoreEvents
          content={content}
          thumbs={seeMoreEventsThumbsContent}
        />
      );
    case "content.see-more-courses":
      return (
        <BrickSeeMoreCourses
          content={content}
          thumbs={seeMoreCoursesThumbsContent}
        />
      );
    case "content.video-text":
      return (
        <BrickVideoText
          videoId={content.url}
          title={content.title}
          subtitle={content.description}
          isMainHomeVideo={isMainHomeVideo}
        />
      );
    case "content.image-title-subtitle-button":
      return (
        <BrickNewsletter
          title={content.title}
          subtitle={content.subtitle}
          buttonText={content.buttonText}
          backgroundImageData={
            imageContent.localFile.childImageSharp.gatsbyImageData
          }
          alternativeText={content.background.alternativeText}
        />
      );
    case "content.download-content":
      return (
        <BrickDownloadContent
          title={content.title}
          files={content.files}
          fileRefs={downloadsContent}
          smallTitle={smallTitle}
        />
      );
    case "content.custom-block":
      return <BrickCustomBlock type={content.type} />;

    default:
      return <div>{content.strapi_component}</div>;
  }
};

export default Brick;
