import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import React, { useCallback } from "react";
import ImageArticles from "../../assets/cards/articles.png";
import ImageGuidelines from "../../assets/cards/guidelines.jpg";
import ImageTherapeutic from "../../assets/cards/therapeutic.jpg";
import ListOfCards from "../../components/cards/list-of-cards";
import CustomButtonText from "../button/custom-button";
import CardLink from "../cards/card-link";

const HomeMedicalInformation = () => {
  const { t } = useI18next();
  const theme = useTheme();
  const cards = [
    <CardLink
      title={t("home.medicalInformation.articles.title")}
      description={t("home.medicalInformation.articles.description")}
      type="article"
      image={
        <img
          style={{ width: "100%" }}
          src={ImageArticles}
          alt="Medical Information Articles"
        />
      }
      labelText={t("home.medicalInformation.articles.label")}
      labelColor={theme.palette.servier.lightBlue}
      centeredText={true}
      link={`/medical-informations`}
    />,
    <CardLink
      title={t("home.medicalInformation.guidelines.title")}
      description={t("home.medicalInformation.guidelines.description")}
      type="article"
      image={
        <img
          style={{ width: "100%" }}
          src={ImageGuidelines}
          alt="Medical Information Guidelines"
        />
      }
      labelText={t("home.medicalInformation.guidelines.label")}
      labelColor={theme.palette.servier.mainBlue}
      centeredText={true}
      link={`/medical-informations`}
    />,
    <CardLink
      title={t("home.medicalInformation.diseaseAwareness.title")}
      description={t("home.medicalInformation.diseaseAwareness.description")}
      type="article"
      image={
        <img
          style={{ width: "100%" }}
          src={ImageTherapeutic}
          alt="Medical Information Therapeutic Areas"
        />
      }
      labelText={t("home.medicalInformation.diseaseAwareness.label")}
      labelColor={theme.palette.servier.green}
      centeredText={true}
      link={`/medical-informations`}
    />,
  ];

  const onSeeMoreClick = useCallback(() => {
    window.dataLayer?.push({
      event: "click_exit_link",
      click_text: "seeMore",
      click_url: "/medical-informations",
    });
  }, []);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <ListOfCards
      title={t("home.medicalInformation.title")}
      cards={cards}
      button={
        <Link
          to={`/medical-informations`}
          onClick={onSeeMoreClick}
          style={{ textDecoration: "none" }}
        >
          <CustomButtonText
            text={t("home.medicalInformation.seeMore")}
            style={{
              letterSpacing: "0.1em",
              minWidth: isMobile ? "330px" : "",
            }}
          />
        </Link>
      }
      backgroundColor={theme.palette.servier.lightBlue + "4D"}
    />
  );
};

export default HomeMedicalInformation;
