import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { downloadFile } from "../../utils";
import CustomTextButton from "../button/custom-text-button";
import CardFile from "../cards/card-file";
import ListOfCards from "../cards/list-of-cards";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@mui/styles";

const BrickDownloadContent = ({
  title,
  files,
  fileRefs,
  smallTitle = false,
}) => {
  const { t } = useI18next();
  const onDowloadAll = () => {
    for (let i = 0; i < files.length; ++i) {
      setTimeout(function () {
        downloadFile(files[i].name, fileRefs[i]);
      }, 200 * i);
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      maxWidth="lg"
      sx={{ my: { xs: 3, lg: 4 }, mx: "auto" }}
      style={{ padding: 0 }}
    >
      <Container style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <Typography variant={smallTitle ? "bodyStrong" : "h3"}>
          {title}
        </Typography>
        {title && !smallTitle && (
          <hr
            color={theme.palette.servier.lightBlue}
            style={{
              width: "65px",
              height: "8px",
              margin: "0",
              borderRadius: 0,
              backgroundColor: theme.palette.servier.lightBlue,
            }}
          ></hr>
        )}
      </Container>
      <ListOfCards
        title=""
        isCentered={false}
        cards={files.map((file, i) => (
          <CardFile
            key={`file__${i}`}
            title={file.caption}
            description={file.name?.replace(/\.[^/.]+$/, "")}
            nameFile={file.name}
            fileRef={fileRefs[i]}
          />
        ))}
        button={<></>}
      />
      <Grid container justifyContent={isMobile ? "center" : "flex-end"}>
        <Grid item>
          <CustomTextButton
            text={t("downloadEverything")}
            onClick={onDowloadAll}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BrickDownloadContent;
