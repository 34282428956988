import { Button } from "@mui/material";
import React from "react";

const CustomButtonTextAndIcon = ({ startIcon, text, onClick, width }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      startIcon={startIcon}
      style={{
        margin: "50px 0 10px 0",
        padding: "10px 16px 10px 12px",
        width: width,
        height: "50px",
        borderRadius: "4px",
        letterSpacing: "0.1em",
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButtonTextAndIcon;
