import { useTheme } from "@mui/styles";
import { graphql, navigate, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import React, { useCallback } from "react";
import CustomButtonText from "../button/custom-button";
import CardLink from "../cards/card-link";
import ListOfCards from "../cards/list-of-cards";
import isEmpty from "lodash/isEmpty";
import { useMediaQuery } from "@mui/material";

const BrickSeeMoreCourses = ({ content, thumbs }) => {
  const theme = useTheme();
  const { t, language } = useI18next();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const onClick = useCallback(() => {
    window.dataLayer?.push({
      course: "click_exit_link",
      click_text: content.buttonText,
      click_url: "/courses",
    });
  }, [content]);

  const getTherapeuticArea = (data, course) => {
    return isEmpty(course)
      ? null
      : data.allStrapiTherapeuticalArea.edges.find(
          (th) => th.node.strapiId === course.therapeutical_area
        );
  };

  const courses = [];
  if (content.course1) courses.push(content.course1);
  if (content.course2) courses.push(content.course2);
  if (content.course3) courses.push(content.course3);
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiTherapeuticalArea {
            edges {
              node {
                strapiId
                Name
                id
                color
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <ListOfCards
            title={content.title}
            cards={courses
              .map((course, i) => {
                const therapeuticArea = getTherapeuticArea(data, course);
                return isEmpty(course) ? null : (
                  <CardLink
                    title={course.Title}
                    type="course"
                    image={
                      thumbs[i] ? (
                        <GatsbyImage
                          image={
                            thumbs[i].localFile.childImageSharp.gatsbyImageData
                          }
                          alt={course.Thumbnail.alternativeText}
                        />
                      ) : null
                    }
                    date={course.date}
                    labelText={
                      course.CME ? t("course.courseCME") : t("course.course")
                    }
                    labelColor={theme.palette.servier.blue2}
                    labelTextColor={theme.palette.primary.main}
                    link={`/courses/${course.Slug}`}
                    hours={course.hours}
                    minutes={course.minutes}
                    therapeuticAreaLabel={therapeuticArea.node.Name}
                    therapeuticAreaColor={therapeuticArea.node.color}
                    credits={course.credits}
                  />
                );
              })
              .filter((card) => null !== card)}
            button={
              content.buttonText ? (
                <CustomButtonText
                  text={content.buttonText}
                  style={{
                    letterSpacing: "0.1em",
                    minWidth: isMobile ? "330px" : "",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    onClick();
                    navigate(`/${language === "en" ? "en" : "es"}/courses`);
                  }}
                />
              ) : (
                <></>
              )
            }
            backgroundColor={content.backgroundColor}
          />
        );
      }}
    />
  );
};

export default BrickSeeMoreCourses;
