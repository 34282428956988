import { graphql, navigate, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useCallback } from "react";
import { formatDate } from "../../utils";
import CustomButtonText from "../button/custom-button";
import CardLink from "../cards/card-link";
import ListOfCards from "../cards/list-of-cards";
import isEmpty from "lodash/isEmpty";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

const getButtonLink = (linkTo) => {
  switch (linkTo) {
    case "editorialContent":
      return `/medical-informations/`;
    case "news":
      return `/news/`;
    case "events":
      return `/live-events/`;
    default:
      return null;
  }
};

const getButton = (linkTo, buttonText, onClick, language, isMobile) => {
  const buttonLink = getButtonLink(linkTo);
  if (!buttonLink) {
    return <></>;
  } else {
    return (
      <CustomButtonText
        text={buttonText}
        style={{
          letterSpacing: "0.1em",
          minWidth: isMobile ? "330px" : "",
          textDecoration: "none",
        }}
        onClick={() => {
          onClick();
          navigate(`/${language === "en" ? "en" : "es"}${buttonLink}`);
        }}
      />
    );
  }
};

const getLink = (slug, type, id) => {
  switch (type) {
    case "events":
      return `/live-events/live-event-${id}`;
    case "news":
      return `/news/${slug}`;
    case "disease-awareness":
    case "article":
      return `/medical-informations/${slug}`;
    default:
      return "/";
  }
};

const getEditorialContentType = (data, editorial_content) => {
  return isEmpty(editorial_content)
    ? null
    : data.allStrapiEditorialContentType.edges.find(
        (ect) => ect.node.strapiId === editorial_content.editorial_content_type
      );
};

const getTherapeuticArea = (data, editorial_content) => {
  return isEmpty(editorial_content)
    ? null
    : data.allStrapiTherapeuticalArea.edges.find(
        (th) => th.node.strapiId === editorial_content.therapeutical_area
      );
};

const isDiseaseAwareness = (editorialContentType) => {
  return editorialContentType?.node.Slug === "disease-awareness";
};

const BrickSeeMore = ({ content, thumbs }) => {
  const onClick = useCallback(() => {
    window.dataLayer?.push({
      event: "click_exit_link",
      click_text: content.buttonText,
      click_url: getButtonLink(content.linkTo),
    });
  }, [content]);
  const { language } = useI18next();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const editorialContents = [];
  if (content.editorial_content_1)
    editorialContents.push(content.editorial_content_1);
  if (content.editorial_content_2)
    editorialContents.push(content.editorial_content_2);
  if (content.editorial_content_3)
    editorialContents.push(content.editorial_content_3);
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiEditorialContentType {
            edges {
              node {
                strapiId
                Slug
                id
                Name
                color
              }
            }
          }
          allStrapiTherapeuticalArea {
            edges {
              node {
                strapiId
                Name
                id
                color
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <ListOfCards
            title={content.title}
            cards={editorialContents
              .map((editorial_content, i) => {
                const editorialContentType = getEditorialContentType(
                  data,
                  editorial_content
                );
                const therapeuticArea = getTherapeuticArea(
                  data,
                  editorial_content
                );
                return isEmpty(editorial_content) ? null : (
                  <CardLink
                    title={editorial_content.Title}
                    type={editorialContentType.node.Slug}
                    image={
                      thumbs[i] ? (
                        <GatsbyImage
                          image={
                            thumbs[i].localFile.childImageSharp.gatsbyImageData
                          }
                          alt={
                            editorial_content.Thumbnail.alternativeText
                              ? editorial_content.Thumbnail.alternativeText
                              : ""
                          }
                        />
                      ) : null
                    }
                    date={formatDate(editorial_content.date)}
                    labelText={
                      !!(editorialContentType && editorialContentType.node)
                        ? editorialContentType.node.Name
                        : null
                    }
                    labelColor={
                      !!(editorialContentType && editorialContentType.node)
                        ? editorialContentType.node.color
                        : null
                    }
                    link={getLink(
                      editorial_content.Slug,
                      editorialContentType.node.Slug,
                      editorial_content.id
                    )}
                    therapeuticAreaLabel={
                      !!(therapeuticArea && therapeuticArea.node)
                        ? therapeuticArea.node.Name
                        : null
                    }
                    therapeuticAreaColor={
                      !!(therapeuticArea && therapeuticArea.node)
                        ? therapeuticArea.node.color
                        : null
                    }
                  />
                );
              })
              .filter((card) => null !== card)}
            button={getButton(
              content.linkTo,
              content.buttonText,
              onClick,
              language,
              isMobile
            )}
            backgroundColor={content.backgroundColor}
          />
        );
      }}
    />
  );
};

export default BrickSeeMore;
