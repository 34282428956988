import { Button } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/styles";

const CustomTextButton = ({ text, onClick }) => {
  const theme = useTheme();
  return (
    <Button
      variant="text"
      style={{
        color: theme.palette.servier.mainBlue,
        fontSize: "14px",
        fontWeight: "900",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CustomTextButton;
